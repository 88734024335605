<div class="page-wrapper auth">
  <div class="page-inner bg_brand_gradient">
    <div class="page-content-wrapper bg-transparent m-0">
      <app-sm-ex-navbar></app-sm-ex-navbar>
        <router-outlet></router-outlet>
      <app-sm-ex-footer></app-sm-ex-footer>
    </div>
  </div>
</div>
<!-- BEGIN Color profile -->
<!-- this area is hidden and will not be seen on screens or screen readers -->
<!-- we use this only for CSS color refernce for JS stuff -->
<p id="js-color-profile" class="d-none">
  <span class="color-primary-50"></span>
  <span class="color-primary-100"></span>
  <span class="color-primary-200"></span>
  <span class="color-primary-300"></span>
  <span class="color-primary-400"></span>
  <span class="color-primary-500"></span>
  <span class="color-primary-600"></span>
  <span class="color-primary-700"></span>
  <span class="color-primary-800"></span>
  <span class="color-primary-900"></span>
  <span class="color-info-50"></span>
  <span class="color-info-100"></span>
  <span class="color-info-200"></span>
  <span class="color-info-300"></span>
  <span class="color-info-400"></span>
  <span class="color-info-500"></span>
  <span class="color-info-600"></span>
  <span class="color-info-700"></span>
  <span class="color-info-800"></span>
  <span class="color-info-900"></span>
  <span class="color-danger-50"></span>
  <span class="color-danger-100"></span>
  <span class="color-danger-200"></span>
  <span class="color-danger-300"></span>
  <span class="color-danger-400"></span>
  <span class="color-danger-500"></span>
  <span class="color-danger-600"></span>
  <span class="color-danger-700"></span>
  <span class="color-danger-800"></span>
  <span class="color-danger-900"></span>
  <span class="color-warning-50"></span>
  <span class="color-warning-100"></span>
  <span class="color-warning-200"></span>
  <span class="color-warning-300"></span>
  <span class="color-warning-400"></span>
  <span class="color-warning-500"></span>
  <span class="color-warning-600"></span>
  <span class="color-warning-700"></span>
  <span class="color-warning-800"></span>
  <span class="color-warning-900"></span>
  <span class="color-success-50"></span>
  <span class="color-success-100"></span>
  <span class="color-success-200"></span>
  <span class="color-success-300"></span>
  <span class="color-success-400"></span>
  <span class="color-success-500"></span>
  <span class="color-success-600"></span>
  <span class="color-success-700"></span>
  <span class="color-success-800"></span>
  <span class="color-success-900"></span>
  <span class="color-fusion-50"></span>
  <span class="color-fusion-100"></span>
  <span class="color-fusion-200"></span>
  <span class="color-fusion-300"></span>
  <span class="color-fusion-400"></span>
  <span class="color-fusion-500"></span>
  <span class="color-fusion-600"></span>
  <span class="color-fusion-700"></span>
  <span class="color-fusion-800"></span>
  <span class="color-fusion-900"></span>
</p>
<!-- END Color profile -->
<!-- base vendor bundle:
  DOC: if you remove pace.js from core please note on
  Internet Explorer some CSS animations may execute before
  a page is fully loaded, resulting 'jump' animations
  + pace.js (recommended)
  + jquery.js (core)
  + jquery-ui-cust.js (core)
  + popper.js (core)
  + bootstrap.js (core)
  + slimscroll.js (extension)
  + app.navigation.js (core)
  + ba-throttle-debounce.js (core)
  + waves.js (extension)
  + smartpanels.js (extension)
  + src/../jquery-snippets.js (core)
-->
<script>
  $("#js-login-btn").click(function(event) {
    // Fetch form to apply custom Bootstrap validation
    let form = $("#js-login")

    if (form[0].checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    form.addClass('was-validated');
    // Perform ajax submit here...
  });
</script>
