import { Injectable } from '@angular/core';
import { DomainService } from '../domain/domain.service';

@Injectable({
  providedIn: 'root'
})
export class HtmlService {

  constructor(
    private serviceDomain: DomainService,
  ) { }

  htmlClass() {
    console.warn('htmlClass');
    let winPath = this.serviceDomain.getDomainPath();
    console.log(`winPath: ${winPath}`);

    // Remover todas las clases del html
    document.documentElement.className = '';

    // Verificar si el path contiene 'external' o 'internal'
    if (winPath.includes('/external/')) {
      console.log('Path is external');
      // Agregar las clases al html
      document.documentElement.classList.add(
        'root-text'
      );
    }

    if (!winPath.includes('/external/')) {
      console.log('Path is internal');
      // Agregar las clases al html
      document.documentElement.classList.add(
        'root-text-sm'
      );
    }
  }

  bodyClass() {
    console.warn('bodyClass');
    let winPath = this.serviceDomain.getDomainPath();
    console.log(`winPath: ${winPath}`);
    // Remover todas las clases del body
    document.body.className = '';

    // Verificar si el path contiene 'external' o 'internal'
    if (winPath.includes('/external/')) {
      console.log('Path is external');
      // Agregar las clases al body
      document.body.classList.add(
        'header-function-fixed',
        'footer_function_fixed',
      );
    }

    if (!winPath.includes('/external/')) {
      console.log('Path is internal');
      // Agregar las clases al body
      document.body.classList.add(
        'mod-bg-1',
        'mod-nav-link',
        'header-function-fixed',
        'nav-function-fixed',
        'footer-function-fixed',

        'nav-function-hidden',
      );
    }
  }
}
