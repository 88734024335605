import { Component } from '@angular/core';

@Component({
  selector: 'app-wo-in-dashboard',
  templateUrl: './wo-in-dashboard.component.html',
  styleUrls: ['./wo-in-dashboard.component.css']
})
export class WoInDashboardComponent {

}
