import { Component } from '@angular/core';

@Component({
  selector: 'app-sm-in-quick-menu',
  templateUrl: './sm-in-quick-menu.component.html',
  styleUrls: ['./sm-in-quick-menu.component.css']
})
export class SmInQuickMenuComponent {

}
