import { Component } from '@angular/core';

@Component({
  selector: 'app-sm-ex-navbar',
  templateUrl: './sm-ex-navbar.component.html',
  styleUrls: ['./sm-ex-navbar.component.css']
})
export class SmExNavbarComponent {

}
