import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { SmExFooterComponent } from './smart/sm-external/sm-ex-footer/sm-ex-footer.component';
import { SmExNavbarComponent } from './smart/sm-external/sm-ex-navbar/sm-ex-navbar.component';

import { SmInPageLogoComponent } from './smart/sm-internal/sm-in-page-logo/sm-in-page-logo.component';
import { SmInNavigationComponent } from './smart/sm-internal/sm-in-navigation/sm-in-navigation.component';
import { SmInNavFooterComponent } from './smart/sm-internal/sm-in-nav-footer/sm-in-nav-footer.component';
import { SmInPageHeaderComponent } from './smart/sm-internal/sm-in-page-header/sm-in-page-header.component';
import { SmInPageFooterComponent } from './smart/sm-internal/sm-in-page-footer/sm-in-page-footer.component';
import { SmInShortcutsComponent } from './smart/sm-internal/sm-in-shortcuts/sm-in-shortcuts.component';
import { SmInColorProfileComponent } from './smart/sm-internal/sm-in-color-profile/sm-in-color-profile.component';
import { SmInQuickMenuComponent } from './smart/sm-internal/sm-in-quick-menu/sm-in-quick-menu.component';

import { SmMdMessengerComponent } from './smart/sm-modal/sm-md-messenger/sm-md-messenger.component';
import { SmMdSettingComponent } from './smart/sm-modal/sm-md-setting/sm-md-setting.component';

import { WoExternalComponent } from './work/wo-external/wo-external.component';
import { WoExLoginComponent } from './work/wo-external/wo-ex-login/wo-ex-login.component';
import { WoExRegisterComponent } from './work/wo-external/wo-ex-register/wo-ex-register.component';

import { WoInternalComponent } from './work/wo-internal/wo-internal.component';
import { WoInDashboardComponent } from './work/wo-internal/wo-in-dashboard/wo-in-dashboard.component';
import { WoInTemplateComponent } from './work/wo-internal/wo-in-template/wo-in-template.component';

import { WoTechnologyComponent } from './work/wo-technology/wo-technology.component';
import { TeRoleActionComponent } from './work/wo-technology/te-role-action/te-role-action.component';
import { TeRoleAuthComponent } from './work/wo-technology/te-role-auth/te-role-auth.component';
import { TeRoleDataComponent } from './work/wo-technology/te-role-data/te-role-data.component';
import { TeRoleFuncComponent } from './work/wo-technology/te-role-func/te-role-func.component';
import { TeRoleGroupComponent } from './work/wo-technology/te-role-group/te-role-group.component';
import { TeRolePermitComponent } from './work/wo-technology/te-role-permit/te-role-permit.component';
import { TeUserDataComponent } from './work/wo-technology/te-user-data/te-user-data.component';
import { TeUserEmailComponent } from './work/wo-technology/te-user-email/te-user-email.component';
import { TeUserIpComponent } from './work/wo-technology/te-user-ip/te-user-ip.component';

import { WoSystemComponent } from './work/wo-system/wo-system.component';
import { SyInformationComponent } from './work/wo-system/sy-information/sy-information.component';

@NgModule({
  declarations: [
    AppComponent,

    SmExFooterComponent,
    SmExNavbarComponent,

    SmInPageLogoComponent,
    SmInNavigationComponent,
    SmInNavFooterComponent,
    SmInPageHeaderComponent,
    SmInPageFooterComponent,
    SmInShortcutsComponent,
    SmInColorProfileComponent,
    SmInQuickMenuComponent,

    SmMdMessengerComponent,
    SmMdSettingComponent,

    WoExternalComponent,
    WoExLoginComponent,
    WoExRegisterComponent,

    WoInternalComponent,
    WoInDashboardComponent,
    WoInTemplateComponent,

    WoTechnologyComponent,
    TeRoleActionComponent,
    TeRoleAuthComponent,
    TeRoleDataComponent,
    TeRoleFuncComponent,
    TeRoleGroupComponent,
    TeRolePermitComponent,
    TeUserDataComponent,
    TeUserEmailComponent,
    TeUserIpComponent,

    WoSystemComponent,
    SyInformationComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
