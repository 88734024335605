<!-- Navbar Logo & Menu -->
<nav class="navbar navbar-expand-lg navbar-light bg-warning-500 sticky-top">
  <div class="container">
    <!--<a class="navbar-brand d-flex align-items-center fw-500 mx-auto" href="#">
      <img src="assets/apps/img/svg/logo/logo-blue.svg"
      class="img-fluid d-inline-block align-top mr-2"
      width="115" height="20" alt="{{ appTitle }}"
      aria-roledescription="logo">
    </a>-->
    <!--<button class="navbar-toggler" type="button"
    data-toggle="collapse" data-target="#navbarSticky"
    aria-controls="navbarText" aria-expanded="false"
    aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSticky">
      <ul class="navbar-nav mx-auto text-center">
        <li class="nav-item active">
          <a class="nav-link" href="external/login">
            <em class="fal fa-user mr-1" aria-hidden="true"></em>
            <span>Ingreso</span>
          </a>
        </li>
        <li class="nav-item active">
          <a class="nav-link" href="external/register">
            <em class="fal fa-user mr-1" aria-hidden="true"></em>
            <span>Registro</span>
          </a>
        </li>
        <li class="nav-item active">
          <a class="nav-link" href="external/active">
            <em class="fal fa-user mr-1" aria-hidden="true"></em>
            <span>Activación</span>
          </a>
        </li>
      </ul>
    </div>-->
    <div class="navbar-nav d-flex flex-row gap-3 mx-auto">
      <ul class="navbar-nav d-flex flex-row">
        <li class="nav-item active mr-1">
          <a class="nav-link" href="external/login">
            <em class="fal fa-user-tie mr-1" aria-hidden="true"></em>
            <span>Ingreso</span>
          </a>
        </li>
        <li class="nav-item active mr-1">
          <a class="nav-link" href="external/register">
            <em class="fal fa-users-crown mr-1" aria-hidden="true"></em>
            <span>Registro</span>
          </a>
        </li>
        <li class="nav-item active mr-1">
          <a class="nav-link" href="external/active">
            <em class="fal fa-user-check mr-1" aria-hidden="true"></em>
            <span>Activación</span>
          </a>
        </li>
      </ul>
    </div>

  </div>
</nav>
