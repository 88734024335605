import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from './guards/auth/auth.guard';

import { WoExternalComponent } from './work/wo-external/wo-external.component';
import { WoExLoginComponent } from './work/wo-external/wo-ex-login/wo-ex-login.component';
import { WoExRegisterComponent } from './work/wo-external/wo-ex-register/wo-ex-register.component';

import { WoInternalComponent } from './work/wo-internal/wo-internal.component';
import { WoInDashboardComponent } from './work/wo-internal/wo-in-dashboard/wo-in-dashboard.component';
import { WoInTemplateComponent } from './work/wo-internal/wo-in-template/wo-in-template.component';

import { WoTechnologyComponent } from './work/wo-technology/wo-technology.component';
import { TeRoleActionComponent } from './work/wo-technology/te-role-action/te-role-action.component';
import { TeRoleAuthComponent } from './work/wo-technology/te-role-auth/te-role-auth.component';
import { TeRoleDataComponent } from './work/wo-technology/te-role-data/te-role-data.component';
import { TeRoleFuncComponent } from './work/wo-technology/te-role-func/te-role-func.component';
import { TeRoleGroupComponent } from './work/wo-technology/te-role-group/te-role-group.component';
import { TeRolePermitComponent } from './work/wo-technology/te-role-permit/te-role-permit.component';
import { TeUserDataComponent } from './work/wo-technology/te-user-data/te-user-data.component';
import { TeUserEmailComponent } from './work/wo-technology/te-user-email/te-user-email.component';
import { TeUserIpComponent } from './work/wo-technology/te-user-ip/te-user-ip.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'external/login' },
  {
    path: 'external', component: WoExternalComponent,
    children: [
      { path: 'login', component: WoExLoginComponent },
      { path: 'register', component: WoExRegisterComponent },
    ],
  },
  {
    path: 'internal', component: WoInternalComponent,
    children: [
      { path: 'dashboard', component: WoInDashboardComponent },
      { path: 'template', component: WoInTemplateComponent },
    ],
  },
  {
    path: 'technology',
    canActivate: [authGuard],
    component: WoTechnologyComponent,
    children: [
      { path: 'role',
        children: [
          { path: 'action', component: TeRoleActionComponent },
          { path: 'auth', component: TeRoleAuthComponent },
          { path: 'data', component: TeRoleDataComponent },
          { path: 'func', component: TeRoleFuncComponent },
          { path: 'group', component: TeRoleGroupComponent },
          { path: 'permit', component: TeRolePermitComponent },
        ],
      },
      { path: 'user',
        children: [
          { path: 'data', component: TeUserDataComponent },
          { path: 'email', component: TeUserEmailComponent },
          { path: 'ip', component: TeUserIpComponent },
        ],
      },
    ],
  },
  { path: '**', redirectTo: 'external/login' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
