import { Component } from '@angular/core';

@Component({
  selector: 'app-wo-internal',
  templateUrl: './wo-internal.component.html',
  styleUrls: ['./wo-internal.component.css']
})
export class WoInternalComponent {

}
