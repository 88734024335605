import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { TgUserData } from 'src/app/interfaces/modules/technology/tg-user-data';
import { JsAuthService } from 'src/app/services/apis/java/spring/js-auth/js-auth.service';
import { JsCheckService } from 'src/app/services/apis/java/spring/js-check/js-check.service';
import { AuthService } from 'src/app/services/functions/auth/auth.service';
import { BaseurlService } from 'src/app/services/functions/baseurl/baseurl.service';
import { DomainService } from 'src/app/services/functions/domain/domain.service';
import { StorageService } from 'src/app/services/functions/storage/storage.service';
import { Environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

declare let bootstrap: any;

@Component({
  selector: 'app-wo-ex-register',
  templateUrl: './wo-ex-register.component.html',
  styleUrls: ['./wo-ex-register.component.css']
})
export class WoExRegisterComponent implements OnInit {
  strgAuth: string = Environment.strg.auth;
  appTitle: string = Environment.title;
  domainMajor: string = '';
  urlFront: string = '';

  isLoading: boolean = false;
  formSubmit: boolean = false;
  registerForm: FormGroup;
  tgUserData: TgUserData[] = [];

  constructor (
    private fb: FormBuilder,
    private router: Router,
    private serviceAuth: AuthService,
    private serviceBaseUrl: BaseurlService,
    private serviceDomain: DomainService,
    private javaSpringAuth: JsAuthService,
    private javaSpringCheck: JsCheckService,
    private serviceStorage: StorageService,
  ) {
    this.registerForm = this.formRegister();
  }

  ngOnInit(): void {
    this.getDomain();
    this.getUrlHref();
  }

  formRegister(): FormGroup {
    return this.fb.group({
      cdLogin: ['', Validators.required],
      cdPassword: ['', Validators.required],
    });
  }

  getDomain() {
    let dmMajor = this.serviceDomain.getDomainInfo();
    this.domainMajor = dmMajor;
  }

  getUrlHref() {
    let urlDomain = this.domainMajor;
    let urlBase = this.serviceBaseUrl.getBaseUrl();
    if (urlBase === '/') { urlBase = ''; }
    this.urlFront = urlDomain + urlBase;
    console.log(`urlFront: ${this.urlFront}`);
  }

  async actionCheck(): Promise<any> {
    try {
      const response = await firstValueFrom(
        this.javaSpringCheck.available()
      );
      console.log('Response from backend check:', response);
      // Devuelve true si el backend está disponible
      return response.status === true;
    } catch (error) {
      console.error('Error al verificar la disponibilidad del backend:', error);
      this.openModalById('modalAlertAvailable');
      // Devuelve false si hay un error o el backend no está disponible
      return false;
    }
  }

  loadingStatus(response: boolean) {
    this.isLoading = response;
  }

  elementsId(id: string) {
    return document.getElementById(id);
  }

  openModalById(modalId: string) {
    const modalElement = this.elementsId(modalId);
    if (modalElement) {
      const modalInfo = new bootstrap.Modal(
        modalElement, {
          backdrop: 'static',
          keyboard: false,
        }
      );
      modalInfo.show();
      // Configura el evento para cerrar el modal solo con el botón "Aceptar"
      modalElement.addEventListener('hide.modal', function (e) {
        // Verifica si el evento se disparó desde un clic fuera del modal o desde la tecla Esc
        if (!modalElement.classList.contains('show')) {
          e.preventDefault();
        }
      });
    } else {
      console.error(`Modal with ID ${modalId} not found.`);
    }
  }

  async logionAction() {
    if (!this.registerForm.valid) {
      this.formSubmit = true;
      return;
    }
    this.loadingStatus(true);
    const available = await this.actionCheck();
    if (!available) { this.loadingStatus(false); }
    if (available) { this.loginProccess(); }
  }

  loginProccess() {
    const { cdLogin, cdPassword } = this.registerForm.value;

    // Crear un objeto de tipo con los datos del formulario
    const payload: TgUserData = {
      cdLogin: cdLogin,
      cdPassword: cdPassword,
    };

    this.javaSpringAuth.login(payload).subscribe({
      next: (response: any) => {
        this.loginEnding(response);
      },
      error: (response: any) => {
        this.loginEnding(response);
      },
      complete: () => {
        console.log('request completed');
      },
    });
  }

  loginEnding(response: any) {
    console.warn('loginEnding');
    console.log('response', response);
    let dataSweet = { html: '', icon: '', title: '', };
    this.loadingStatus(false);
    if (response.status === 403) {
      dataSweet.html = 'Credenciales Incorrectas';
      dataSweet.icon = 'error';
      dataSweet.title = 'Advertencia!';
      this.messageLoading(dataSweet, response);
    }
    if (response.status === 200) {
      dataSweet.html = 'Acceso Autorizado';
      dataSweet.icon = 'success';
      dataSweet.title = 'Exito!';
      this.messageLoading(dataSweet, response);
    }
  }

  messageLoading(message: any, response: any) {
    console.warn('messageLoading');
    console.log('message', message);
    console.log('response', response);
    Swal.fire({
      allowOutsideClick: false,
      confirmButtonText: 'Entendido',
      customClass: {
        confirmButton: 'rounded-0 btn btn-md btn-success',
      },
      html: `<span class="text-dark">${message.html}</span>`,
      icon: message.icon,
      title: `<h3>${message.title}!</h3>`,
    }).then(() => {
      if (response.status === 200) {
        this.loginAuth(response);
      }
    });
  }

  loginAuth(response: any) {
    console.warn('loginAuth');
    console.log('response', response);
    console.log('body', response.body);

    let dataStrg = this.strgGet();
    if (dataStrg) { this.strgRemove(); }
    if (dataStrg) { this.strgSet(response.body); }
    if (!dataStrg) { this.strgSet(response.body); }

    let verifyStrg = this.strgGet();
    if (verifyStrg) {
      this.router.navigate(
        ['/technology/user/data']
      );
    }
  }

  // Obtener token de la autenticación
  strgGet() {
    return this.serviceStorage.sessionGet(
      this.strgAuth
    );
  }

  // Asignar token de la autenticación
  strgSet(response: any) {
    this.serviceStorage.sessionSetJson(
      this.strgAuth,
      response
    );
  }

  // Remover token de la autenticación
  strgRemove() {
    this.serviceStorage.sessionRemove(
      this.strgAuth
    );
  }
}
