import { Component } from '@angular/core';

@Component({
  selector: 'app-sm-md-setting',
  templateUrl: './sm-md-setting.component.html',
  styleUrls: ['./sm-md-setting.component.css']
})
export class SmMdSettingComponent {

}
