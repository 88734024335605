<!-- BEGIN Page Logo -->
<div class="page-logo">
  <a href="#" class="page-logo-link press-scale-down d-flex align-items-center position-relative" data-toggle="modal" data-target="#modal-shortcut">
      <img src="assets/smart/img/logo.png" alt="SmartAdmin WebApp" aria-roledescription="logo">
      <span class="page-logo-text mr-1">SmartAdmin WebApp</span>
      <span class="position-absolute text-white opacity-50 small pos-top pos-right mr-2 mt-n2"></span>
      <i class="fal fa-angle-down d-inline-block ml-1 fs-lg color-primary-300"></i>
  </a>
</div>
<!-- END Page Logo -->
