import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BaseurlService } from 'src/app/services/functions/baseurl/baseurl.service';
import { DomainService } from 'src/app/services/functions/domain/domain.service';
import { Environment } from 'src/environments/environment';

@Component({
  selector: 'app-te-role-action',
  templateUrl: './te-role-action.component.html',
  styleUrls: ['./te-role-action.component.css']
})
export class TeRoleActionComponent implements OnInit {
  appTitle: string = Environment.title;
  domainMajor: string = '';
  urlFront: string = '';

  constructor (
    private router: Router,
    private serviceBaseUrl: BaseurlService,
    private serviceDomain: DomainService,
  ) {}

  ngOnInit(): void {
    this.getDomain();
    this.getUrlHref();
  }

  getDomain() {
    let dmMajor = this.serviceDomain.getDomainInfo();
    this.domainMajor = dmMajor;
  }

  getUrlHref() {
    let urlDomain = this.domainMajor;
    let urlBase = this.serviceBaseUrl.getBaseUrl();
    if (urlBase === '/') { urlBase = ''; }
    this.urlFront = urlDomain + urlBase;
    console.log(`urlFront: ${this.urlFront}`);
  }
}
