<!-- card begin -->
<div class="card shadow-lg bg-white rounded-0">
  <!-- card body -->
  <div class="card-body p-2">


    <div class="row mb-2">


      <div class="col-12 mb-2">
        <!-- card begin -->
        <div class="card shadow-lg bg-white rounded-0">
          <!-- card body begin -->
          <div class="card-body">
            Contenido
          </div>
          <!-- card body end -->
        </div>
        <!-- card end -->
      </div>


      <div class=" col-xs-12 col-sm-12 col-md-2 mb-2">
        <!-- card begin -->
        <div class="card shadow-lg bg-white rounded-0 h-100">
          <!-- card header -->
          <div class="card-header">
            <!--<h4 class="mb-1">Data Table With Buttons</h4>-->
            <p class="mb-0">
              Informacion Importante
            </p>
          </div>
          <!-- card body begin -->
          <div class="card-body">
            Contenido
          </div>
          <!-- card body end -->
        </div>
        <!-- card end -->
      </div>


      <div class=" col-xs-12 col-sm-12 col-md-8 mb-2">
        <!-- card begin -->
        <div class="card shadow-lg bg-white rounded-0 h-100">
          <!-- card header -->
          <div class="card-header">
            <!--<h4 class="mb-1">Data Table With Buttons</h4>-->
            <p class="mb-0">
              Informacion Importante
            </p>
          </div>
          <!-- card body begin -->
          <div class="card-body">
            <div class="table-card">
              <div class="table-responsive table-wrapper">
                <table class="table table-bordered table-sm table-hover table-striped w-100">
                  <thead class="table-success">
                    <tr>
                      <!-- Encabezados de la tabla -->
                      <th *ngFor="let header of pageColumn">
                        {{ header }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let data of pageData">
                      <td *ngFor="let header of pageColumn">
                        {{ data[header] }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <!-- card body end -->
        </div>
        <!-- card end -->
      </div>


      <div class=" col-xs-12 col-sm-12 col-md-2 mb-2">
        <!-- card begin -->
        <div class="card shadow-lg bg-white rounded-0 h-100">
          <!-- card header -->
          <div class="card-header">
            <!--<h4 class="mb-1">Data Table With Buttons</h4>-->
            <p class="mb-0">
              Informacion Importante
            </p>
          </div>
          <!-- card body begin -->
          <div class="card-body">
            Contenido Tarjeta
          </div>
          <!-- card body end -->
        </div>
        <!-- card end -->
      </div>


    </div>

  </div>
</div>
<!-- card end -->
