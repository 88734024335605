<!-- Footer -->
<footer class="navbar navbar-expand-lg navbar-light bg-warning-500 fixed-bottom">
  <div class="container d-flex flex-column flex-lg-row justify-content-center align-items-center text-center">
    <ul class="navbar-nav d-flex flex-row gap-3">
      <li class="nav-item active mr-1">
        <a href="external/doc" class="nav-link text-center">
          <em class="fal fa-books mr-1" aria-hidden="true"></em>
          <span>Documentation</span>
        </a>
      </li>
      <li class="nav-item active mr-1">
        <a href="external/help" class="nav-link text-center">
          <em class="fal fa-question-square mr-1" aria-hidden="true"></em>
          <span>Ayuda</span>
        </a>
      </li>
    </ul>
  </div>
</footer>

<!-- Footer App -->
<!--<footer class="page-footer position-absolute pos-bottom pos-left pos-right p-3" role="contentinfo">
  <div class="d-flex align-items-center flex-1 text-white text-center">
    <span class="hidden-md-down fw-700">
      <a href="https://www.adisonjimenez.net"
      class="text-info"
      title="Adison Jimenez"
      target="_blank"
      rel="noopener">
        Adison Jimenez
      </a>
      Copyright © 2014 - 2024.
    </span>
  </div>
  <div>
    <ul class="list-table m-0">
      <li class="pl-3">
        <a href="external/doc" class="text-white text-center fw-700">
          <em class="fal fa-question-circle mr-1" aria-hidden="true"></em>
          <span>Documentation</span>
        </a>
      </li>
      <li class="pl-3">
        <a href="external/help" class="text-white text-center fw-700">
          <em class="fal fa-question-circle mr-1" aria-hidden="true"></em>
          <span>Ayuda</span>
        </a>
      </li>
    </ul>
  </div>
</footer>-->
