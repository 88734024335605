import { Component } from '@angular/core';

@Component({
  selector: 'app-wo-system',
  templateUrl: './wo-system.component.html',
  styleUrls: ['./wo-system.component.css']
})
export class WoSystemComponent {

}
