import { Component } from '@angular/core';

@Component({
  selector: 'app-sm-in-color-profile',
  templateUrl: './sm-in-color-profile.component.html',
  styleUrls: ['./sm-in-color-profile.component.css']
})
export class SmInColorProfileComponent {

}
