<!-- BEGIN NAV FOOTER -->
<div class="nav-footer shadow-top">
  <a href="#" onclick="return false;" data-action="toggle" data-class="nav-function-minify" class="hidden-md-down">
      <i class="ni ni-chevron-right"></i>
      <i class="ni ni-chevron-right"></i>
  </a>
  <ul class="list-table m-auto nav-footer-buttons">
      <li>
          <a href="javascript:void(0);" data-toggle="tooltip" data-placement="top" title="Chat logs">
              <i class="fal fa-comments"></i>
          </a>
      </li>
      <li>
          <a href="javascript:void(0);" data-toggle="tooltip" data-placement="top" title="Support Chat">
              <i class="fal fa-life-ring"></i>
          </a>
      </li>
      <li>
          <a href="javascript:void(0);" data-toggle="tooltip" data-placement="top" title="Make a call">
              <i class="fal fa-phone"></i>
          </a>
      </li>
  </ul>
</div>
<!-- END NAV FOOTER -->
