<div *ngIf="isLoading" class="loading-init">
  <div class="loading-spinner"></div>
</div>

<!-- Contenido App -->
<main id="js-page-content" role="main" class="page-content">
  <div class="d-flex align-items-center app-vh-50 vh_100">
    <div class="container py-5">
      <div class="row justify-content-center">
        <div class="col-12 col-xs-12 col-sm-10 col-md-8 mx-auto text-center">
          <div class="card shadow-lg bg-white rounded-0">
            <div class="card-body">
              <form [formGroup]="loginForm"
              class="needs-validation"
              novalidate id="formLogin"
              [ngClass]="{'was-validated': formSubmit}">
                <div class="row">
                  <div class="col-12 mb-6">
                    <img src="assets/apps/img/svg/logo/logo-blue.svg"
                    class="img-fluid d-inline-block align-top mr-2"
                    width="115" height="20" alt="{{ appTitle }}"
                    aria-roledescription="logo">
                  </div>
                  <div class="col-12 mb-6">
                    <h1 class="fw_light text-dark font-weight-bold">
                      <span>Ingreso Sistema</span>
                    </h1>
                  </div>
                  <div class="col-12 mb-3">
                    <div class="card shadow-lg bg-white rounded-0">
                      <div class="card-body">
                        <label class="form-label mb-3" for="cdLogin">
                          <span class="text-dark">Usuario</span>
                          <span class="text-danger">(*)</span>
                        </label>
                        <input type="text"
                        class="form-control custom-input rounded-0"
                        id="cdLogin" name="cdLogin"
                        placeholder="Usuario"
                        formControlName="cdLogin"
                        autocomplete="cdLogin"
                        required="">
                        <div class="invalid_tooltip invalid-feedback">
                          <div class="alert alert-danger alert-dismissible fade show rounded-0 p-1 mb-0" role="alert">
                            <div class="d-flex align-items-center">
                              <div class="alert-icon width-1">
                                <i class="fal fa-sync fs-sm fa-spin"></i>
                              </div>
                              <div class="flex-1">
                                <span class="h6 m-0 fw-700">
                                  Campo requerido y obligatorio
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 mb-6">
                    <div class="card shadow-lg bg-white rounded-0">
                      <div class="card-body">
                        <label class="form-label mb-3" for="cdPassword">
                          <span class="text-dark">Contraseña</span>
                          <span class="text-danger">(*)</span>
                        </label>
                        <input type="password"
                        class="form-control custom-input rounded-0"
                        id="cdPassword" name="cdPassword"
                        placeholder="Contraseña"
                        formControlName="cdPassword"
                        autocomplete="cdPassword"
                        required="">
                        <div class="invalid_tooltip invalid-feedback">
                          <div class="alert alert-danger alert-dismissible fade show rounded-0 p-1 mb-0" role="alert">
                            <div class="d-flex align-items-center">
                              <div class="alert-icon width-1">
                                <i class="fal fa-sync fs-sm fa-spin"></i>
                              </div>
                              <div class="flex-1">
                                <span class="h6 m-0 fw-700">
                                  Campo requerido y obligatorio
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 mb-3">
                    <div class="row">
                      <div class="col-6 mb-2">
                        <button class="btn btn-md btn-success w-100 rounded-0"
                        type="button" (click)="logionAction()">
                          <span>Loguearme</span>
                        </button>
                      </div>
                      <div class="col-6 mb-2">
                        <button class="btn btn-md btn-info w-100 rounded-0"
                        type="button" (click)="logionAction()">
                          <span>Registrarme</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 mb-0">
                    <span class="text-dark">
                      Inicie sesión para continuar con el ingreso al sistema
                    </span>
                  </div>
                </div>
              </form>
              <div class="row">
                <div class="col-12">
                  <div class="mt-4 text-center">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>

<!-- Modal: Servicio Inaccesible -->
<div class="modal fade" id="modalAlertAvailable"
name="modalAlertAvailable" tabindex="-1"
aria-hidden="true" aria-labelledby="modalAlertData"
data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog modal-sm modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content rounded-0">
      <div class="modal-header justify-content-center rounded-0 border-0 bg-danger">
        <h4 class="modal-title" id="modalAlertData">
          <span class="text-white">Servicio Inaccesible</span>
        </h4>
      </div>
      <div class="modal-body text-center">
        <p class="text-danger mb-2">
          <span>
            Estamos experimentando problemas con nuestro servicio.
          </span>
        </p>
        <p class="text-dark mb-2">
          <span>
            Nuestro equipo está trabajando para resolver la situación
            y permitir que continúes con tu proceso sin inconvenientes.
            Te agradecemos tu paciencia y te pedimos que lo intentes de nuevo más tarde.
          </span>
        </p>
      </div>
      <div class="modal-footer justify-content-center">
        <button class="btn btn-md btn-success rounded-0"
        type="button" data-dismiss="modal">
          <span>Aceptar</span>
        </button>
        <button type="button" class="btn btn-md btn-primary rounded-0">
          <span>Soporte Técnico</span>
        </button>
      </div>
    </div>
  </div>
</div>
