import { Component } from '@angular/core';

@Component({
  selector: 'app-wo-in-template',
  templateUrl: './wo-in-template.component.html',
  styleUrls: ['./wo-in-template.component.css']
})
export class WoInTemplateComponent {

}
