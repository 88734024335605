import { Component } from '@angular/core';

@Component({
  selector: 'app-sm-ex-footer',
  templateUrl: './sm-ex-footer.component.html',
  styleUrls: ['./sm-ex-footer.component.css']
})
export class SmExFooterComponent {

}
