import { Component } from '@angular/core';

@Component({
  selector: 'app-sm-in-page-header',
  templateUrl: './sm-in-page-header.component.html',
  styleUrls: ['./sm-in-page-header.component.css']
})
export class SmInPageHeaderComponent {

}
