const domainLocalhost = 'http://127.0.0.1:8105';
const domainEngSoft = 'https://dsx.engsoft.app';
const domainEngTest = 'https://dsx.engtest.app';
const domainAdisonNet = 'https://dsx.adisonjimenez.net';
const domainAdisonWork = 'https://dsx.adisonjimenez.work';

export const Environment = {
  production: true,
  api: {
    java: {
      spring: {
        host: `${domainLocalhost}/portal_security_spring`,
        app: `${domainEngSoft}/portal_security_spring`,
        net: `${domainAdisonNet}/portal_security_spring`,
        test: `${domainEngTest}/portal_security_spring`,
        work: `${domainAdisonWork}/portal_security_spring`,
      },
    },
    php: {
      laravel: {
        host: `${domainLocalhost}/portal_security_laravel`,
        app: `${domainEngSoft}/portal_security_laravel`,
        net: `${domainAdisonNet}/portal_security_laravel`,
        test: `${domainEngTest}/portal_security_laravel`,
        work: `${domainAdisonWork}/portal_security_laravel`,
      },
      slim: {
        host: `${domainLocalhost}/portal_security_slim`,
        app: `${domainEngSoft}/portal_security_slim`,
        net: `${domainAdisonNet}/portal_security_slim`,
        test: `${domainEngTest}/portal_security_slim`,
        work: `${domainAdisonWork}/portal_security_slim`,
      },
    },
  },
  tmpl: {
    smart: {
      host: `${domainLocalhost}/version.4.5.1/html-full/dist`,
      app: `${domainEngSoft}/version.4.5.1/html-full/dist`,
      net: `${domainAdisonNet}/version.4.5.1/html-full/dist`,
      test: `${domainEngTest}/version.4.5.1/html-full/dist`,
      work: `${domainAdisonWork}/version.4.5.1/html-full/dist`,
    },
  },
  strg: {
    auth: 'ajAppAuth',
  },
  title: 'Adison Jimenez',
}
