import { Component } from '@angular/core';

@Component({
  selector: 'app-sm-in-page-logo',
  templateUrl: './sm-in-page-logo.component.html',
  styleUrls: ['./sm-in-page-logo.component.css']
})
export class SmInPageLogoComponent {

}
