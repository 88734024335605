import { Component } from '@angular/core';

@Component({
  selector: 'app-sm-in-page-footer',
  templateUrl: './sm-in-page-footer.component.html',
  styleUrls: ['./sm-in-page-footer.component.css']
})
export class SmInPageFooterComponent {

}
