import { DOCUMENT } from '@angular/common';
import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { Environment } from 'src/environments/environment';
import { ConsoleService } from './services/functions/console/console.service';
import { DomainService } from './services/functions/domain/domain.service';
import { HtmlService } from './services/functions/html/html.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  appTitle: string = Environment.title;

  constructor(
    private renderer: Renderer2,
    private serviceConsole: ConsoleService,
    private serviceDomain: DomainService,
    private serviceHtml: HtmlService,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  ngOnInit(): void {
    this.getHtmlSrvc();
    this.getDomainData();
  }

  getHtmlSrvc() {
    console.warn('getHtmlSrvc');
    this.serviceHtml.htmlClass();
    this.serviceHtml.bodyClass();
  }

  getDomainData() {
    console.warn('getDomainData');
    let domainVerify = this.serviceDomain.getDomainVerify();
    console.log(`domainVerify: ${domainVerify}`);

    let domainInfo = this.serviceDomain.getDomainInfo();
    console.log(`domainInfo: ${domainInfo}`);

    // Google Analytics
    //this.analytics(domainInfo);
    // Google Tag Manager
    //this.tagManager(domainInfo);
  }

  // Google Analytics
  analytics(domainInfo: any) {
    const domainMapping: { [key: string]: string } = {
      'https://dev.adisonjimenez.net': 'G-2SP73V14QW',
      'https://stag.adisonjimenez.net': 'G-538SQRE0J3',
      'https://www.adisonjimenez.net': 'G-2PKZHFSXBG',

      'https://dev.adisonjimenez.work': 'G-D09K30ZLMS',
      'https://stag.adisonjimenez.work': 'G-21KVGT06HJ',
      'https://www.adisonjimenez.work': 'G-59V4Q8DY6F',

      'https://dev.engsoft.app': 'G-FZBQ897QP8',
      'https://stag.engsoft.app': 'G-NLBEFP8NVR',
      'https://www.engsoft.app': 'G-YB7310050E',

      'https://dev.engtest.app': 'G-MPS7BWDN3V',
      'https://stag.engtest.app': 'G-DC0WVFKPQ2',
      'https://www.engtest.app': 'G-3Q0LWSNZP8',
    };
    const ggAnalytics = domainMapping[domainInfo] || '';
    if (ggAnalytics) { this.anltcsGtmScript(ggAnalytics); }
  }

  // Google Analytics
  anltcsGtmScript(key: string) {
    const commentStart = this.renderer.createComment('Google Tag (gtag.js)');
    const scriptStart = this.renderer.createElement('script');
    const urlSrc = `https://www.googletagmanager.com/gtag/js?id=${key}`;
    this.renderer.setAttribute(scriptStart, 'async', 'true');
    this.renderer.setAttribute(scriptStart, 'src', urlSrc);

    const scriptEnd = this.renderer.createElement('script');
    scriptEnd.type = 'text/javascript';
    scriptEnd.text = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${key}');
    `;

    const commentEnd = this.renderer.createComment('End Google Tag (gtag.js)');

    this.renderer.appendChild(document.head, commentStart);
    this.renderer.appendChild(document.head, scriptStart);
    this.renderer.appendChild(document.head, scriptEnd);
    this.renderer.appendChild(document.head, commentEnd);
  }

  // Google Tag Manager
  tagManager(domainInfo: any) {
    const domainMapping: { [key: string]: string } = {
      'https://dev.adisonjimenez.net': 'GTM-KV8FP6TH',
      'https://stag.adisonjimenez.net': 'GTM-MJCBCGJT',
      'https://www.adisonjimenez.net': 'GTM-MLRPDB9P',

      'https://dev.adisonjimenez.work': 'GTM-TF7FHQ4R',
      'https://stag.adisonjimenez.work': 'GTM-NDSZ234X',
      'https://www.adisonjimenez.work': 'GTM-TMKKS69J',

      'https://dev.engsoft.app': 'GTM-M3XGDQMX',
      'https://stag.engsoft.app': 'GTM-WLSLV5ZT',
      'https://www.engsoft.app': 'GTM-P586TD48',

      'https://dev.engtest.app': 'GTM-PKP2B99S',
      'https://stag.engtest.app': 'GTM-TN7X4T9T',
      'https://www.engtest.app': 'GTM-THQ7FVF7',
    };
    const ggTagManager = domainMapping[domainInfo] || '';
    if (ggTagManager) { this.tagGtmScript(ggTagManager); }
    if (ggTagManager) { this.tagGtmNoScript(ggTagManager); }
  }

  // Google Tag Manager
  tagGtmScript(key: string) {
    const commentStart = this.renderer.createComment('Google Tag Manager');
    const script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.text = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${key}');
    `;
    const commentEnd = this.renderer.createComment('End Google Tag Manager');
    this.renderer.appendChild(document.head, commentStart);
    this.renderer.appendChild(document.head, script);
    this.renderer.appendChild(document.head, commentEnd);
  }

  // Google Tag Manager
  tagGtmNoScript(key: string) {
    const commentStart = this.renderer.createComment('Google Tag Manager (noscript)');
    const noscript = this.renderer.createElement('noscript');
    const iframe = this.renderer.createElement('iframe');
    const urlSrc = `https://www.googletagmanager.com/ns.html?id=${key}`;
    this.renderer.setAttribute(iframe, 'src', urlSrc);
    this.renderer.setAttribute(iframe, 'height', '0');
    this.renderer.setAttribute(iframe, 'width', '0');
    this.renderer.setAttribute(iframe, 'style', 'display:none;visibility:hidden');
    this.renderer.appendChild(noscript, iframe);
    const commentEnd = this.renderer.createComment('End Google Tag Manager (noscript)');

    this.renderer.appendChild(this.document.body, commentStart);
    this.renderer.appendChild(this.document.body, noscript);
    this.renderer.appendChild(this.document.body, commentEnd);
  }
}
