import { Component } from '@angular/core';

@Component({
  selector: 'app-sm-in-shortcuts',
  templateUrl: './sm-in-shortcuts.component.html',
  styleUrls: ['./sm-in-shortcuts.component.css']
})
export class SmInShortcutsComponent {

}
